import React from "react";
import { PropTypes } from "prop-types";
import { MrbRadioButtons } from "mrb/components/radio";
import { MrbQuerySearchInput } from "mrb/components/input";
import { HomeDeviceSelect } from "application/home/components";
import { mergeCss } from "mrb/common/utils";
import { observer } from "mobx-react";

function NakiNigunHomePageHeaderTemplate({ nakiNigunHomeViewStore, t }) {
    const {
        anyDevice,
        contentTypeOptions,
        changeContentTypeFilter,
        queryUtility,
        inSearchMode,
        rootStore,
        deviceSelectStore,
        nakiNigunGenericSearchViewStore,
    } = nakiNigunHomeViewStore;
    return (
        <div className="c-home__header">
            <div className="c-home__header__input__wrapper">
                <div className="u-animation--fadeIn">
                    <MrbQuerySearchInput
                        className="c-input c-input--search c-input--search--lrg c-input--base jq-onboarding-search"
                        queryUtility={nakiNigunGenericSearchViewStore.queryUtility}
                        showSearchButton={false}
                        t={t}
                        maxLength={50}
                    />
                </div>
            </div>

            <div
                className={mergeCss(
                    "c-home__header__actions",
                    !rootStore.premiumSubscriptionStore.subscriptionExist ? "c-home__header__actions--secondary" : ""
                )}
            >
                <div className="u-display--flex u-display--flex--ai--center">
                    <div className="u-animation--fadeIn">
                        {anyDevice && !inSearchMode && (
                            <div className="u-mar--right--sml">
                                <HomeDeviceSelect
                                    store={deviceSelectStore}
                                    userDeviceStore={rootStore.userDeviceStore}
                                    t={t}
                                />
                            </div>
                        )}
                    </div>
                    {!inSearchMode && (
                        <div className="u-animation--fadeIn">
                            <MrbRadioButtons
                                id="naki-nigun-home-content-type-control"
                                name="naki-nigun-home-content-type-control"
                                options={contentTypeOptions}
                                onChange={changeContentTypeFilter}
                                value={queryUtility.filter.contentType}
                                t={t}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

NakiNigunHomePageHeaderTemplate.propTypes = {
    nakiNigunHomeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(NakiNigunHomePageHeaderTemplate);
